<!-- Mouse event blocker for pointer leave -->
@if (coverRectCords && !parentIsNgxMenu) {
    <div
        class="owner-mask"
        [style.top]="coverRectCords.top + 'px'"
        [style.left]="coverRectCords.left + 'px'"
        [style.height]="coverRectCords.height + 'px'"
        [style.width]="coverRectCords.width + 'px'"
        [style.background]="showDebugOverlay ? '#f004' : '#0000'"
        style="z-index: -1"
        (pointerenter)="pointerHasBeenOverMask=true"
        (pointerleave)="stopCloseTimer()"
        (pointermove)="pointerHasBeenOverMask=true"
        (click)="isLockedOpen = true"
    ></div>
}

@if (!parentIsNgxMenu) {
    <div class="void"
        [style.background]="showDebugOverlay ? '#00f4' : '#0000'"
        (pointerenter)="pointerIsOnVoid = true; hasBootstrapped && !isLockedOpen && startCloseTimer()"
        (pointerleave)="pointerIsOnVoid = false"
        (pointerdown)="hasBootstrapped && closeOnVoid(true)"
        (pointermove)="hasBootstrapped && !isLockedOpen && startCloseTimer()"
        (click)="closeOnVoid(true)"
        (wheel)="onWheel($event)"
    ></div>
}

@if (!template) {
    <table (pointerenter)="stopCloseTimer()">
        <tbody>
            @for (item of items; track item) {
                <!-- A row with a click action -->
                @if (item != 'separator' && item.separator != true && item['_visible']) {
                    <tr #row
                        [class.disabled]="item['_disabled']"
                        (click)="!item['_disabled'] && onMenuItemClick(item, row, true)"
                        [class.hover]="row['hover']"
                        [class.open]="row['_open']"
                        (pointerenter)="row['hover'] = true; startHoverTimer(item, row)"
                        (pointerleave)="row['hover'] = false; stopHoverTimer(item)"
                    >

                        @if (showIconColumn) {
                            <td class="icon">
                                @if (matIconRx.test(item['_icon'] ?? item.icon)) {
                                    <img [src]="item['_icon'] ?? item.icon"/>
                                }
                                @else {
                                    <mat-icon
                                        [fontIcon]="item['_icon'] ?? item.icon"
                                        [style.color]="item.iconColor"
                                    />
                                }
                            </td>
                        }

                        <!-- 'Normal' action based item -->
                        <td class="label"
                            [style.padding-left]="showIconColumn ? 0 : '16px'"
                        >
                            <a
                                #anchor
                                [attr.target]="item.linkTarget"
                                [attr.href]="(item['_link'] || item.link) ? sanitizer.bypassSecurityTrustUrl(item['_link'] || item.link) : undefined"
                            >
                                @if ($any(item.labelTemplate)?.prototype) {
                                    <ng-container
                                        [ngTemplateOutlet]="$any(item).labelTemplate"
                                        [ngTemplateOutletContext]="{
                                            '$implicit': data,
                                            'dialog': dialogRef,
                                            'context': item['_context'],
                                            'item': item,
                                            'element': anchor,
                                            'menu': this
                                        }"
                                    />
                                }
                                @else {
                                    @if ($any(item)?.labelTemplate) {
                                        {{$any(item)?.labelTemplate(data || {})}}
                                    }
                                    @else {
                                        <div [innerHTML]="item['_formattedLabel']"></div>
                                    }
                                }
                            </a>
                        </td>

                        @if (showShortcutColumn) {
                            <td class="shortcut">
                                {{item.shortcutLabel}}
                            </td>
                        }

                        <td style="min-width: 16px">
                            @if ((
                                    (item['children']?.length > 0) ||
                                    (item['_children']?.length > 0) ||
                                    item.childTemplate ||
                                    item.children?.['call'] ||
                                    item.childrenResolver
                                ) &&
                                !item['_isResolving']
                            ) {
                                <mat-icon
                                    style="transform: translateY(2px)"
                                >
                                    chevron_right
                                </mat-icon>
                            }

                            @if (item['_isResolving']) {
                                <mat-progress-spinner
                                    mode="indeterminate"
                                    [diameter]="20"
                                    style="margin-right: 4px"
                                />
                            }
                        </td>
                    </tr>
                }
                @else if (item != 'separator' && item.separator == true) {
                    <!-- Separator with label -->
                    <tr
                        class="disabled separator"
                    >
                        <td
                            class="center"
                            [attr.colspan]="2 + (showIconColumn ? 1 : 0) + (showShortcutColumn ? 1 : 0)"
                        >
                            <span class="hr">
                                {{item['label'] || ''}}
                            </span>
                        </td>
                    </tr>
                }
                @else if (item == 'separator') {
                    <!-- Separator -->
                    <tr
                        class="disabled separator"
                    >
                        <td
                            [attr.colspan]="2 + (showIconColumn ? 1 : 0) + (showShortcutColumn ? 1 : 0)"
                        >
                            <hr/>
                        </td>
                    </tr>
                }
            }
        </tbody>
    </table>
}
@else {
    @if (templateType == 'template') {
        <div style="display: contents;" (pointerenter)="stopCloseTimer()">
            <ng-container
                [ngTemplateOutlet]="template"
                [ngTemplateOutletContext]="{
                    '$implicit': data,
                    'dialog': dialogRef,
                    'context': parentContext,
                    'item': parentItem,
                    'element': this.viewContainer?.element?.nativeElement,
                    'menu': this
                }"
            />
        </div>
    }
    @else {
        <div style="display: contents;" (pointerenter)="stopCloseTimer()">
            <ng-container
                [cdkPortalOutlet]="componentPortal"
            />
        </div>
    }
}

@if (showDebugOverlay) {
    <div>
        <div>hbs: {{hasBootstrapped}}</div>
        <div>pov: {{pointerIsOnVoid}}</div>
        <div>ilo: {{isLockedOpen}}</div>
        <div>hbom: {{pointerHasBeenOverMask}}</div>

        <div>type: {{templateType}}</div>
    </div>
}
